import * as CryptoJS from 'crypto-js';
import * as sjcl from 'sjcl';

export function encryptData(data: string, secretKey: string): string {
	const encrypted = sjcl.encrypt(data, secretKey);
	return encrypted.toString();
}

export function decryptData(data: string, secretKey: string): string {
	return sjcl.decrypt(data, secretKey);
}
export const getLabsValidator = (): string => {
	return encrypt(`${Date.now()}`);
};

const secretKey = CryptoJS.enc.Utf8.parse('NgDBLWa9qeutSMtM5uMylk27yjtP41ou');
const iv = CryptoJS.enc.Utf8.parse('6qpjd3oB1dQ6k+GR');
// Encrypt
export const encrypt = (plainText: string): string => {
	const encrypted = CryptoJS.AES.encrypt(plainText, secretKey, {
		iv,
	}).toString();
	return encrypted;
};

// Decrypt
export const decrypt = (cipherText: string): string => {
	const bytes = CryptoJS.AES.decrypt(cipherText, secretKey, { iv });
	const decrypted = bytes.toString(CryptoJS.enc.Utf8);
	return decrypted;
};
